(function() {
  'use strict';

  angular
    .module('breno.main.sections.talks', []);

})();

(function () {
  'use strict';

  angular
    .module('breno.main.sections.talks')
    .directive('talks', talks);

  /** @ngInject */
  function talks() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/main/sections/talks/talks.html',
      controller: TalksController,
      controllerAs: 'vm',
      scope: {
      },
      bindToController: true
    };

    return directive;
  }

  /** @ngInject */
  function TalksController(TEXTS, TALKS) {
    var vm = this;

    vm.TEXTS = TEXTS.reverse();
    vm.TALKS = TALKS.reverse();
  }
})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.talks')
    .constant('TALKS', [
      {// 0
        name: 'Snarks Fluxo-críticos',
        target: 'https://speakerdeck.com/brenolf/snarks-fluxo-criticos',
        year: 2013,
        language: 'pt'
      },
      {// 1
        name: 'Extending Snarks',
        target: 'https://speakerdeck.com/brenolf/extending-snarks',
        year: 2014,
        language: 'en'
      },
      {// 2
        name: 'Como eu fiz um jogo em JavaScript?',
        target: 'http://slides.com/brenolf/como-eu-fiz-um-jogo-em-javascript#/',
        year: 2015,
        language: 'pt'
      },
      {// 3
        name: 'ES6 Rocks!',
        target: 'http://slides.com/brenolf/es6-rocks#/',
        year: 2015,
        language: 'pt'
      },
      {// 4
        name: 'Teoria e prática: Conjuntos disjuntos?',
        target: 'http://slides.com/brenolf/teoria-e-pratica#/',
        year: 2015,
        language: 'pt'
      },
      {// 5
        name: 'A Study of Critical Snarks',
        target: 'https://speakerdeck.com/brenolf/a-study-of-critical-snarks',
        year: 2015,
        language: 'pt'
      },
      {// 6
        name: 'Algoritmos genéticos em JavaScript',
        target: 'https://www.emaze.com/@AILICQCL/algoritmos-genticos-em-javascript',
        year: 2015,
        language: 'pt'
      },
      {// 7
        name: 'Aprendiz de Descritores de Mistura Gaussiana',
        target: 'https://speakerdeck.com/brenolf/aprendiz-de-descritores-de-mistura-gaussiana',
        year: 2017,
        language: 'pt'
      },
      {// 8
        name: 'How Machine Learning improves the UX of 500K merchants',
        target: 'https://speakerdeck.com/brenolf/how-machine-learning-improves-the-ux-of-500k-merchants',
        year: 2018,
        language: 'en'
      },
      {
        // 9
        name: 'Modern Gold Mining: Leveraging Deep Learning to predict GMV for 100k+ shops',
        target: 'https://speakerdeck.com/brenolf/modern-gold-mining-leveraging-deep-learning-to-predict-gmv-for-100k-plus-shops',
        year: 2019,
        language: 'en'  
      }
    ])
    .constant('TEXTS', [
      {// 0
        name: 'Hypohamiltonian Snarks Have a 5-flow',
        target: '/assets/pdf/freitas-silva-lucchesi-15.pdf',
        year: 2015,
        language: 'en'
      },
      {// 1
        name: 'Why I’m becoming sassy',
        target: 'https://medium.com/@brenolf/why-i-m-becoming-sassy-8b4fe456558c',
        year: 2015,
        language: 'en'
      },
      {// 2
        name: 'Plus one minus one is not zero*',
        target: 'https://medium.com/@brenolf/plus-one-minus-one-is-not-zero-3eae94c7efcf',
        year: 2015,
        language: 'en'
      },
      {// 3
        name: 'Designing a designer',
        target: 'https://medium.com/@brenolf/designing-a-designer-2a139d46a68f',
        year: 2015,
        language: 'en'
      },
      {// 4
        name: 'A Minimum Description Classification Method Prototype',
        target: '/assets/pdf/freitas-almeida-silva-17.pdf',
        year: 2017,
        language: 'pt'
      }
    ]);

})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.work', []);

})();

(function () {
  'use strict';

  angular
    .module('breno.main.sections.work')
    .directive('work', work);

  /** @ngInject */
  function work() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/main/sections/work/work.html',
      controller: WorkController,
      controllerAs: 'vm',
      scope: {
      },
      bindToController: true
    };

    return directive;
  }

  /** @ngInject */
  function WorkController(PROJECTS) {
    var vm = this;
    vm.DATA = PROJECTS;

    vm.projects = [3, 4, 0, 1, 2, 5];
  }
})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.work')
    .constant('PROJECTS', [
      {// 0
        name: 'dash',
        target: '//dash.kraiom.com/',
        text: 'A very simple yet challenging browser and mobile game I built from scratch where you must try to memorize different patterns and play as fast as you can.'
      },
      {// 1
        name: 'eunice festas',
        target: '//eunicefestas.com.br/',
        text: 'Web site for a party company built with PHP and JavaScript with full content management.'
      },
      {// 2
        name: 'myfriend',
        target: '//myfriend.breno.io/',
        text: 'Free open-source project aimed to help shelterless dogs to find a home. It features a recommendation system that matches user’s and dog’s traits.'
      },
      {// 3
        name: 'kajoo',
        target: '//kajoo.com.br/',
        text: 'Developed the backend for a counting system with high-availability and stress requirements with NodeJS and a data visualization system with AngularJS and SocketIO.'
      },
      {// 4
        name: 'polyjuice',
        target: '//github.com/brenolf/polyjuice',
        text: 'Developer tool to help programmers migrating seamlessly from different JavaScript linting tools.'
      },
      {// 5
        name: 'breno',
        target: '//www.behance.net/gallery/23135823/Personal-2015-website',
        text: 'My personal page is always changing, maybe because of the feeling of always having it fresh.  That’s why I also keep my old layouts as landmarks of improvement.'
      }
    ]);

})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.splash', []);

})();

(function () {
  'use strict';

  angular
    .module('breno.main.sections.splash')
    .directive('splash', splash);

  /** @ngInject */
  function splash() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/main/sections/splash/splash.html',
      controller: SplashController,
      controllerAs: 'vm',
      scope: {
      },
      bindToController: true
    };

    return directive;
  }

  /** @ngInject */
  function SplashController() {
  }
})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.end', []);

})();

(function () {
  'use strict';

  angular
    .module('breno.main.sections.end')
    .directive('end', end);

  /** @ngInject */
  function end() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/main/sections/end/end.html',
      controller: EndController,
      controllerAs: 'vm',
      scope: {
      },
      bindToController: true
    };

    return directive;
  }

  /** @ngInject */
  function EndController() {
  }
})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections.about', []);

})();

(function () {
  'use strict';

  angular
    .module('breno.main.sections.about')
    .directive('about', about);

  /** @ngInject */
  function about() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/main/sections/about/about.html',
      controller: AboutController,
      controllerAs: 'vm',
      scope: {
      },
      bindToController: true
    };

    return directive;
  }

  /** @ngInject */
  function AboutController() {
    var vm = this;

    activate();

    function activate() {
      var today = new Date();
      var birthdate = new Date(1993, 7, 12, 3, 40);
      var oneYear = 1000 * 60 * 60 * 24 * 365;

      vm.age = Math.floor((today - birthdate) / oneYear);
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('breno.main', [
      'breno.main.sections'
    ]);

})();

(function() {
  'use strict';

  angular
    .module('breno.main')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'app/main/main.html',
        controller: 'MainController',
        controllerAs: 'vm'
      });
  }

})();

(function() {
  'use strict';

  angular
    .module('breno.main')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController() {
  }
})();

(function() {
  'use strict';

  angular
    .module('breno', [
      'ngSanitize',
      'ui.router',
      'duScroll',

      'breno.main'
    ]);

})();

(function() {
  'use strict';

  angular
    .module('breno')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log) {

    $log.debug('runBlock end');
  }

})();

(function() {
  'use strict';

  angular
    .module('breno')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('/');
  }

})();

(function() {
  'use strict';

  angular
    .module('breno')
    .config(config);

  /** @ngInject */
  function config($logProvider) {
    // Enable log
    $logProvider.debugEnabled(true);
  }

})();

(function() {
  'use strict';

  angular
    .module('breno.main.sections', [
      'breno.main.sections.about',
      'breno.main.sections.splash',
      'breno.main.sections.talks',
      'breno.main.sections.work',
      'breno.main.sections.end'
    ]);

})();

angular.module("breno").run(["$templateCache", function($templateCache) {$templateCache.put("app/main/main.html","<section class=\"main\"><splash></splash><about></about><hr class=\"main__hr\"><work></work><hr class=\"main__hr\"><talks></talks><end></end></section>");
$templateCache.put("app/main/sections/about/about.html","<article class=\"about\" id=\"hello\"><h2>hello</h2><div class=\"about__text grid__col grid__col--2-of-4 grid__col--centered\">Hello, my name is Breno Freitas!<br><br>I’m a {{vm.age}} years old Brazilian computer scientist based in Ottawa, Canada. I always loved playing with computers and making them do some tricks under my command; that’s why I ended up pursuing a career on computer science &mdash; take a look at <a class=\"about__text__link\" href=\"/assets/pdf/cv.pdf\">my r&eacute;sum&eacute;</a>.<br><br>My areas of interest are many, altough I’m mainly passionate about theoretical computer science and artificial intelligence; Machine Learning being my major topic of study. I’m currently working for <a class=\"about__text__link\" href=\"https://www.shopify.ca/\" target=\"_blank\" rel=\"noopener noreferrer\">Shopify</a>, making commerce better for everyone!</div></article>");
$templateCache.put("app/main/sections/end/end.html","<footer class=\"end\"><div class=\"end__logo\"><img src=\"assets/images/logo.png\" alt=\"Breno Lima de Freitas\"></div><div class=\"grid__col grid__col--2-of-3 grid__col--centered end__links\"><a href=\"mailto:breno@breno.io\"><i class=\"fa fa-envelope\"></i></a> <a href=\"https://www.linkedin.com/in/brenolf\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-linkedin\"></i></a> <a href=\"https://github.com/brenolf\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-github\"></i></a> <a href=\"https://codepen.io/brenolf/\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-codepen\"></i></a> <a href=\"http://behance.net/brenolimad7b24\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-behance\"></i></a> <a href=\"https://twitter.com/brenolf_\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-twitter\"></i></a> <a href=\"https://medium.com/@brenolf/\" target=\"_blank\" rel=\"noopener noreferrer\"><i class=\"fa fa-medium\"></i></a></div></footer>");
$templateCache.put("app/main/sections/splash/splash.html","<article class=\"splash\"><div class=\"grid__col grid__col--1-of-3 splash__name\"><div>Breno Freitas</div></div><div class=\"grid__col grid__col--2-of-3 splash__links\"><div class=\"splash__links__inner\"><a du-smooth-scroll=\"hello\">hello</a> <a du-smooth-scroll=\"work\">work</a> <a du-smooth-scroll=\"talks\" class=\"splash__links__inner__hide\">talks</a></div></div><div class=\"splash__title\"><img class=\"splash__title__logo\" src=\"assets/images/logo.png\" alt=\"Breno Lima de Freitas\"><h1 class=\"splash__title__text\">make <span>it</span> happen.</h1></div><div class=\"splash__gradient\"></div></article>");
$templateCache.put("app/main/sections/talks/talks.html","<article class=\"talks\" id=\"talks\"><h2>talks &amp; texts</h2><div class=\"talks__intro grid__col grid__col--2-of-4 grid__col--centered\">I always try to make things that matter, and that’s why I eventually end up giving some talks on many different subjects. I also like writting, even though I’m not good at allocating time slots in order to do it more often. Here’s a selection of my previous talks, papers and articles. If you need a picture of myself for publishing purposes, please <a class=\"talks__piece__list__link\" href=\"https://drive.google.com/file/d/1Bq8JR3zgDn3h4ZDOPhRVdQW-ExeUzdPO/view?usp=sharing\" target=\"_blank\">use this this one</a>.</div><div><div class=\"grid__col grid__col--3-of-8 grid__col--push-1-of-6 talks__piece\"><h3 class=\"talks__piece__subtitle\">Talks</h3><ul class=\"talks__piece__list\"><li ng-repeat=\"talk in vm.TALKS\"><a class=\"talks__piece__list__link\" ng-href=\"{{talk.target}}\">{{talk.year}} - [{{talk.language | uppercase}}] {{talk.name}}</a></li></ul></div><div class=\"grid__col grid__col--3-of-8 talks__piece\"><h3 class=\"talks__piece__subtitle\">Texts</h3><ul class=\"talks__piece__list\"><li ng-repeat=\"text in vm.TEXTS\"><a class=\"talks__piece__list__link\" ng-href=\"{{text.target}}\">{{text.year}} - [{{text.language | uppercase}}] {{text.name}}</a></li></ul></div></div></article>");
$templateCache.put("app/main/sections/work/work.html","<article class=\"work\" id=\"work\"><h2>work</h2><div ng-repeat=\"(index, i) in vm.projects\" class=\"grid__col grid__col--1-of-3 work__list__card work__list__card--card{{i}} work__list__card--color{{index}}\"><a class=\"work__list__card__wrapper\" ng-href=\"{{vm.DATA[i].target}}\" target=\"_blank\" rel=\"noopener noreferrer\"><div class=\"work__list__card__wrapper__img\"><img class=\"work__list__card__wrapper__img__element\" ng-src=\"assets/images/projects/{{i}}.png\" alt=\"project\"></div><h4 class=\"work__list__card__wrapper__title\">{{vm.DATA[i].name}}</h4><p class=\"work__list__card__wrapper__dsc\">{{vm.DATA[i].text}}</p></a></div></article>");}]);